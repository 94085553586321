import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import './CardRegoleGame.css';
import './StiliComuni.css'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import InputTextPT from "./InputTextPT";
import ButtonCard from "./ButtonCard";


function isNumericString(str) {
    return /^[0-9]+$/.test(str);
}

class CardRegoleGame extends React.Component
{
    constructor(props) {
        super(props);
        this.state =
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            punteggioAttivazioneEMP:100,
            durataEMP:0,
            punteggioAttivazioneAirstrike:300,
            minutiRiparazione:5,
            minutiDecontaminazione:30,
            bonusBasiMinuto:2,
            bonusConquistaBracciale:5,
            bonusDistruzioneBase:25,
            bonusDistruzioneAirstrike:40,
            bonusAttaccoNucleare:50

        }

        this.ConfermaModifica = this.ConfermaModifica.bind(this);

     }


    componentDidMount()
    {
      var ParametriRichiesta = new Object();
      ParametriRichiesta.token = this.state.Token;
      ParametriRichiesta.idgame = this.state.IDGame;
      ParametriRichiesta.cmd = "g";


      axios.post('https://play.airsoftdigital.com/api/rulesmngt.php', JSON.stringify(ParametriRichiesta))
      .then(response => {
          if(response.data.status==="OK")
          {

            this.setState(prevState => ({
              Token:this.state.Token,
              IDGame:this.state.IDGame,
              punteggioAttivazioneEMP: response.data.punteggioAttivazioneEMP,
              durataEMP: response.data.durataEMP,
              punteggioAttivazioneAirstrike: response.data.punteggioAttivazioneAirstrike,
              minutiRiparazione: response.data.minutiRiparazione,
              minutiDecontaminazione: response.data.minutiDecontaminazione,
              bonusBasiMinuto: response.data.bonusBasiMinuto,
              bonusConquistaBracciale: response.data.bonusConquistaBracciale,
              bonusDistruzioneBase: response.data.bonusDistruzioneBase,
              bonusDistruzioneAirstrike: response.data.bonusDistruzioneAirstrike,
              bonusAttaccoNucleare: response.data.bonusAttaccoNucleare,
              nukeCodeR: response.data.nukeCodeR,
              nukeCodeB: response.data.nukeCodeB,
              nukeCodeY: response.data.nukeCodeY,
            }));

          }
          else
          {
              alert("Errore di connessione. ")
          }
      });
    }

    ConfermaModifica()
    {
        var punteggioEMP;
        var tempoEMP;
        var punteggioAttivazioneAirstrike;
        var minutiRiparazione;
        var minutiDecontaminazione;
        var bonusBasiMinuto;
        var bonusDistruzioneBase;
        var bonusDistruzioneAirstrike;
        var bonusAttaccoNucleare;
        var bonusConquistaBracciale;
        var nukeCodeR;
        var nukeCodeB;
        var nukeCodeY;

        if(document.getElementById("punteggioEMP").value!="")
        {
            punteggioEMP = document.getElementById("punteggioEMP").value;
        }
        else
        {
            punteggioEMP = document.getElementById("punteggioEMP").placeholder;
        }

        if(document.getElementById("tempoEMP").value!="")
        {
            tempoEMP = document.getElementById("tempoEMP").value;
        }
        else
        {
            tempoEMP = document.getElementById("tempoEMP").placeholder;
        }

        if(document.getElementById("punteggioAttivazioneAirstrike").value!="")
        {
            punteggioAttivazioneAirstrike = document.getElementById("punteggioAttivazioneAirstrike").value;
        }
        else
        {
            punteggioAttivazioneAirstrike = document.getElementById("punteggioAttivazioneAirstrike").placeholder;
        }

        if(document.getElementById("minutiRiparazione").value!="")
        {
            minutiRiparazione = document.getElementById("minutiRiparazione").value;
        }
        else
        {
            minutiRiparazione = document.getElementById("minutiRiparazione").placeholder;
        }

        if(document.getElementById("minutiDecontaminazione").value!="")
        {
            minutiDecontaminazione = document.getElementById("minutiDecontaminazione").value;
        }
        else
        {
            minutiDecontaminazione = document.getElementById("minutiDecontaminazione").placeholder;
        }
        if(document.getElementById("bonusBasiMinuto").value!="")
        {
            bonusBasiMinuto = document.getElementById("bonusBasiMinuto").value;
        }
        else
        {
            bonusBasiMinuto = document.getElementById("bonusBasiMinuto").placeholder;
        }
        if(document.getElementById("bonusConquistaBracciale").value!="")
        {
            bonusConquistaBracciale = document.getElementById("bonusConquistaBracciale").value;
        }
        else
        {
            bonusConquistaBracciale = document.getElementById("bonusConquistaBracciale").placeholder;
        }
        if(document.getElementById("bonusDistruzioneBase").value!="")
        {
            bonusDistruzioneBase = document.getElementById("bonusDistruzioneBase").value;
        }
        else
        {
            bonusDistruzioneBase = document.getElementById("bonusDistruzioneBase").placeholder;
        }
        if(document.getElementById("bonusDistruzioneAirstrike").value!="")
        {
            bonusDistruzioneAirstrike = document.getElementById("bonusDistruzioneAirstrike").value;
        }
        else
        {
            bonusDistruzioneAirstrike = document.getElementById("bonusDistruzioneAirstrike").placeholder;
        }
        if(document.getElementById("bonusAttaccoNucleare").value!="")
        {
            bonusAttaccoNucleare = document.getElementById("bonusAttaccoNucleare").value;
        }
        else
        {
            bonusAttaccoNucleare = document.getElementById("bonusAttaccoNucleare").placeholder;
        }
        if(document.getElementById("nukeCodeR").value!="")
        {
            nukeCodeR = document.getElementById("nukeCodeR").value;
        }
        else
        {
            nukeCodeR = document.getElementById("nukeCodeR").placeholder;
        }
        if(document.getElementById("nukeCodeB").value!="")
        {
            nukeCodeB = document.getElementById("nukeCodeB").value;
        }
        else
        {
            nukeCodeB = document.getElementById("nukeCodeB").placeholder;
        }
        if(document.getElementById("nukeCodeY").value!="")
        {
            nukeCodeY = document.getElementById("nukeCodeY").value;
        }
        else
        {
            nukeCodeY = document.getElementById("nukeCodeY").placeholder;
        }


        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;
        ParametriRichiesta.cmd = "u";
        ParametriRichiesta.punteggioEMP = punteggioEMP;
        ParametriRichiesta.tempoEMP = tempoEMP;
        ParametriRichiesta.punteggioAttivazioneAirstrike = punteggioAttivazioneAirstrike;
        ParametriRichiesta.minutiRiparazione = minutiRiparazione;
        ParametriRichiesta.minutiDecontaminazione = minutiDecontaminazione;
        ParametriRichiesta.bonusBasiMinuto = bonusBasiMinuto;
        ParametriRichiesta.bonusConquistaBracciale = bonusConquistaBracciale;
        ParametriRichiesta.bonusDistruzioneBase = bonusDistruzioneBase;
        ParametriRichiesta.bonusDistruzioneAirstrike = bonusDistruzioneAirstrike;
        ParametriRichiesta.bonusAttaccoNucleare = bonusAttaccoNucleare;
        ParametriRichiesta.nukeCodeR = nukeCodeR;
        ParametriRichiesta.nukeCodeB = nukeCodeB;
        ParametriRichiesta.nukeCodeY = nukeCodeY;

        axios.post('https://play.airsoftdigital.com/api/rulesmngt.php', JSON.stringify(ParametriRichiesta))
        .then(response => {
            if(response.data.status==="OK")
            {
                alert("Regole modificate");
            }
            else
            {
                alert("Errore di connessione. ")
            }
        });

    }


    render()
    {




            return <div className="CardGenerica">
            <div className="titolettoCard TestoVerde">PUNTEGGI E BONUS</div>
            <div className="labelCard">Bonus basi per minuto</div>
            <InputTextPT id="bonusBasiMinuto" testo={this.state.bonusBasiMinuto}/>
            <div className="labelCard">Bonus conquista base</div>
            <InputTextPT id="bonusConquistaBracciale" testo={this.state.bonusConquistaBracciale}/>
            <div className="labelCard">Bonus distruzione base</div>
            <InputTextPT id="bonusDistruzioneBase" testo={this.state.bonusDistruzioneBase}/>
            <div className="labelCard">Bonus distruzione con AirStrike</div>
            <InputTextPT id="bonusDistruzioneAirstrike" testo={this.state.bonusDistruzioneAirstrike}/>
            <div className="labelCard">Bonus attacco nucleare (sottratto per base)</div>
            <InputTextPT id="bonusAttaccoNucleare" testo={this.state.bonusAttaccoNucleare}/>

            <div className="separatore-50"></div>
            <div className="titolettoCard TestoVerde">PARAMETRI PERK</div>
            <div className="labelCard">Punteggio attivazione EMP</div>
            <InputTextPT id="punteggioEMP" testo={this.state.punteggioAttivazioneEMP}/>
            <div className="labelCard">Durata EMP (minuti)</div>
            <InputTextPT id="tempoEMP" testo={this.state.durataEMP}/>
            <div className="labelCard">Punteggio Attivazione Airstrike</div>
            <InputTextPT id="punteggioAttivazioneAirstrike" testo={this.state.punteggioAttivazioneAirstrike}/>
            <div className="labelCard">Tempo per riparazione (minuti)</div>
            <InputTextPT id="minutiRiparazione" testo={this.state.minutiRiparazione}/>
            <div className="labelCard">Tempo decontaminazione nucleare (minuti)</div>
            <InputTextPT id="minutiDecontaminazione" testo={this.state.minutiDecontaminazione}/>
            <div className="separatore-50"></div>
            <div className="titolettoCard TestoVerde">CODICI NUCLEARI</div>
            <div className="labelCard">Codice Nucleare Rosso</div>
            <InputTextPT id="nukeCodeR" testo={this.state.nukeCodeR}/>
            <div className="labelCard">Codice Nucleare Blu</div>
            <InputTextPT id="nukeCodeB" testo={this.state.nukeCodeB}/>
            <div className="labelCard">Codice Nucleare Giallo</div>
            <InputTextPT id="nukeCodeY" testo={this.state.nukeCodeY}/>
            <ButtonCard className="bottoneModaleAggiungi" testo="Aggiorna Regole" colore="verde" click={this.ConfermaModifica}/>






        </div>;
    };
}


export default CardRegoleGame;
