import React from "react";
import './ButtonPT.css';

class ButtonPT extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
        };
      }


    render(){

        return <button className="Bottone-Base" id={this.props.id} onClick={this.props.click}>
            {this.props.testo}
        </button>
    };
}


export default ButtonPT;