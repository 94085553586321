import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import './Ingame.css';
import AppMenu from './AppMenu';

import L, { bounds } from 'leaflet';
import Leaflet from 'leaflet'
import { useMap } from "react-leaflet";

import ButtonPT from './ButtonPT';
import InputTextPT from './InputTextPT';
import axios from 'axios';
import VistaDisturbata from "./VistaDisturbata";
import BasePopup from "./BasePopup";

import {
    Map,
    TileLayer,
    Marker,
    Popup,
    MapContainer
} from 'react-leaflet'
import {SyncProblemTwoTone} from "@material-ui/icons";


const dimensioneBase = 28;
const dimensionPOI = 30;

var lat=0;
var lon=0;
var head=0;

var top = 0.0;
var left = 0.0;
var right = 0.0;
var bottom = 0.0;

var mounted = 0;

function generateString()
{
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for ( var i = 0; i < 8; i++ ) {
          result += characters.charAt(Math.floor(Math.random() *
     charactersLength));
       }
       return result;
}

// ICONE
const IconPOI = new L.Icon({
    iconUrl: require('./images/poiicon.png').default,
    iconAnchor: new L.Point(dimensionPOI / 2, dimensionPOI / 2),
    popupAnchor: [
        0, - dimensionPOI / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensionPOI, dimensionPOI)
});

const IconUser = new L.Icon({
    iconUrl: require('./images/user-icon.png').default,
    iconAnchor: new L.Point(dimensionPOI / 2, dimensionPOI / 2),
    popupAnchor: [
        0, - dimensionPOI / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensionPOI, dimensionPOI),

});

const IconNuclear = new L.Icon({
    iconUrl: require('./images/nuclear-icon.png').default,
    iconAnchor: new L.Point(dimensionPOI / 2, dimensionPOI / 2),
    popupAnchor: [
        0, - dimensionPOI / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensionPOI, dimensionPOI),

});

const IconBASENEUTRALE = new L.Icon({
    iconUrl: require('./images/flag-neutral.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconBASEROSSA = new L.Icon({
    iconUrl: require('./images/flag-red.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconBASEBLU = new L.Icon({
    iconUrl: require('./images/flag-blue.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconBASEGIALLA = new L.Icon({
    iconUrl: require('./images/flag-yellow.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});


const IconOSPEDALENEUTRALE = new L.Icon({
    iconUrl: require('./images/ospedale-neutral.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconOSPEDALEROSSA = new L.Icon({
    iconUrl: require('./images/ospedale-red.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconOSPEDALEBLU = new L.Icon({
    iconUrl: require('./images/ospedale-blue.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconOSPEDALEGIALLA = new L.Icon({
    iconUrl: require('./images/ospedale-yellow.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});


const IconARMERIANEUTRALE = new L.Icon({
    iconUrl: require('./images/armeria-neutral.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});


const IconARMERIAROSSO = new L.Icon({
    iconUrl: require('./images/armeria-red.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconARMERIABLU = new L.Icon({
    iconUrl: require('./images/armeria-blue.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconARMERIAGIALLO = new L.Icon({
    iconUrl: require('./images/armeria-yellow.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconNOISE = new L.Icon({
    iconUrl: require('./images/base-noise.png').default,
    iconAnchor: new L.Point(dimensioneBase*3, dimensioneBase*3),
    popupAnchor: [
        0, - dimensioneBase * 3
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase * 6, dimensioneBase * 6)
});

const IconFire = new L.Icon({
    iconUrl: require('./images/flame-icon.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconREPAIRROSSO = new L.Icon({
    iconUrl: require('./images/repair_rosso.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconREPAIRBLU = new L.Icon({
    iconUrl: require('./images/repair_blu.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});

const IconREPAIRGIALLO = new L.Icon({
    iconUrl: require('./images/repair_giallo.png').default,
    iconAnchor: new L.Point(dimensioneBase / 2, dimensioneBase / 2),
    popupAnchor: [
        0, - dimensioneBase / 2
    ],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(dimensioneBase, dimensioneBase)
});




const position = [45.6778028, 9.1623907];





class Ingame extends React.Component {
    constructor(props) {
        super(props);
        this.AggiornaStatoGame = this.AggiornaStatoGame.bind(this);
        this.AggiornaCoordinateGiocatore = this.AggiornaCoordinateGiocatore.bind(this);
        this.state = {
            lat: 45.6778028,
            lng: 9.1623907,
            zoom: 18,
            token: this.props.Token,
            idgame: this.props.IDGame,
            poi: [],
            stations: [],
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            disturboAttivo:0,
            EMP:0,
            latitude:0,
            longitude:0,
            heading:0,
            AirStrike:0,
        }
        this.mapRef = React.createRef();

        this.AggiornaStatoGame();


    }


    AggiornaStatoGame() {
        var TotScoreR = 0;
        var TotScoreB = 0;
        var TotScoreY = 0;

        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.token;
        ParametriRichiesta.idgame = this.state.idgame;


        axios.post('https://play.airsoftdigital.com/api/gamestatus.php', JSON.stringify(ParametriRichiesta)).then(response => {

            if (response.data.status === "OK") {

              var nleft=45.681959;
              var nright=45.677641;
              var ntop=9.153843;
              var nbottom=9.161611;


              //imposto i bound sui punti di interesse
              /*
              if(response.data.poi.count>0)
              {
                nleft = response.data.poi[0].latitude;
                nright = response.data.poi[0].latitude;
                ntop = response.data.poi[0].longitude;
                nbottom = response.data.poi[0].longitude;


                for(var i=0; i<response.data.poi.length; i++)
                {
                    if(nleft>response.data.poi[i].latitude) nleft=response.data.poi[i].latitude;
                    if(nright<response.data.poi[i].latitude) nright=response.data.poi[i].latitude;
                    if(nbottom>response.data.poi[i].longitude) nbottom=response.data.poi[i].longitude;
                    if(ntop<response.data.poi[i].longitude) ntop=response.data.poi[i].longitude;
                }
              }*/

              //imposto i bounds sulle stazioni
              if(response.data.stations.stations>0)
              {
                nleft = response.data.stations[0].latitude;
                nright = response.data.stations[0].latitude;
                ntop = response.data.stations[0].longitude;
                nbottom = response.data.stations[0].longitude;


                for(var i=0; i<response.data.stations.length; i++)
                {
                    if(nleft>response.data.stations[i].latitude) nleft=response.data.stations[i].latitude;
                    if(nright<response.data.stations[i].latitude) nright=response.data.stations[i].latitude;
                    if(nbottom>response.data.stations[i].longitude) nbottom=response.data.stations[i].longitude;
                    if(ntop<response.data.stations[i].longitude) ntop=response.data.stations[i].longitude;
                }
              }



              for(var i=0; i<response.data.stations.length; i++)
                {
                    TotScoreR += parseInt(response.data.stations[i].score_R);
                    TotScoreB += parseInt(response.data.stations[i].score_B);
                    TotScoreY += parseInt(response.data.stations[i].score_Y);
                }

                if(mounted)
                {
                this.setState(prevState => ({
                    lat: prevState.lat,
                    lng: prevState.lon,
                    zoom: prevState.zoom,
                    token: prevState.token,
                    idgame: prevState.idgame,
                    poi: response.data.poi,
                    stations: response.data.stations,
                    top: ntop,
                    left: nleft,
                    right: nright,
                    bottom: nbottom,
                    EMP:response.data.EMP,
                    EMPAttivo:response.data.EMPAttivo,
                    AirStrike:response.data.AirStrike,
                }));
                }

                this.props.AggiornaPunteggio(TotScoreR, TotScoreB, TotScoreY, this.state.EMP, this.state.AirStrike, response.data.stations);


            } else {
                alert("Problemi di connessione. Impossibile recuperare lo stato del game.")
            }
        });
    }


    AggiornaCoordinateGiocatore()
    {


    }





    componentDidMount() {
        this.interval = setInterval(this.AggiornaStatoGame, 5000);
        this.intervalPos = setInterval(this.AggiornaCoordinateGiocatore, 2000);
        // Assumo che 'mounted' sia una variabile globale. Se è una proprietà dell'oggetto, usa 'this.mounted'
        mounted = 1;

        // Verifica se l'evento DeviceOrientation è supportato
        if ('DeviceOrientationEvent' in window) {
            // Aggiunge un ascoltatore di eventi per l'orientamento del dispositivo
            window.addEventListener('deviceorientation', function(event) {
                // Ottiene i dati di orientamento
                var alpha = event.alpha; // Rotazione attorno all'asse z (0-360 gradi)
                this.setState({
                    heading: alpha,
                });
                // Usa i valori di alpha, beta e gamma per la tua logica
                //alert(alpha);
            });
        } else {
            console.log('Il tuo dispositivo non supporta l\'orientamento della bussola');
        }


        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function(position) {
                const lat = position.coords.latitude;
                const lon = position.coords.longitude;
                const head = position.coords.heading;

                this.setState({
                    latitude: lat,
                    longitude: lon,
                    heading: head,
                });
                console.log("coord utente: " + this.state.latitude +" "+ this.state.longitude + " heading:" + this.state.heading);
            }.bind(this));
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    render() {

            if(this.state.left!=0)
            {

            const mapbounds = [[this.state.left,this.state.top],[this.state.right,this.state.bottom]];

            const poilist = this.state.poi;
            const listItems = poilist.map((currpoi) => <Marker position={
                    [currpoi.latitude, currpoi.longitude]
                }
                icon={this.state.EMPAttivo==1? IconNOISE : IconPOI}
                key={
                    currpoi.poiname
            }>
                <Popup>{
                    !(this.state.EMPAttivo==1)? currpoi.poiname : generateString()
                }</Popup>
            </Marker>);


            const stationlist = this.state.stations;
            const listStations = stationlist.map((currstation) => {


                if (currstation.icon == "1") { //renderizzo le icone delle basi
                    if (currstation.status == "N") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconBASENEUTRALE}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "R") {

                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconBASEROSSA}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "B") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconBASEBLU}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "Y") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconBASEGIALLA}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!this.state.EMPAttivo ? currstation.stationname : generateString()}
                                punteggioRosso={!this.state.EMPAttivo?currstation.score_R : generateString()}
                                punteggioBlu={!this.state.EMPAttivo?currstation.score_B : generateString()}
                                punteggioGiallo={!this.state.EMPAttivo?currstation.score_Y : generateString()}
                                minutesleft={!this.state.EMPAttivo?currstation.minutesleft : generateString()}
                                utm_z={!this.state.EMPAttivo?currstation.utm_zone : generateString()}
                                utm_e={!this.state.EMPAttivo?currstation.utm_east : generateString()}
                                utm_n={!this.state.EMPAttivo?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    }
                }

                if (currstation.icon == "2") { //renderizzo le icone delle valigie
                    if (currstation.status == "N") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }

                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconOSPEDALENEUTRALE}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "R") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconOSPEDALEROSSA}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "B") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconOSPEDALEBLU}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "Y") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconOSPEDALEGIALLA}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    }
                }
                if (currstation.icon == "3") { //renderizzo le icone dei bersagli
                    if (currstation.status == "N") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconARMERIANEUTRALE}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "R") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconARMERIAROSSO}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "B") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconARMERIABLU}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!(this.state.EMPAttivo==1)? currstation.stationname : generateString()}
                                punteggioRosso={!(this.state.EMPAttivo==1)?currstation.score_R : generateString()}
                                punteggioBlu={!(this.state.EMPAttivo==1)?currstation.score_B : generateString()}
                                punteggioGiallo={!(this.state.EMPAttivo==1)?currstation.score_Y : generateString()}
                                minutesleft={!(this.state.EMPAttivo==1)?currstation.minutesleft : generateString()}
                                utm_z={!(this.state.EMPAttivo==1)?currstation.utm_zone : generateString()}
                                utm_e={!(this.state.EMPAttivo==1)?currstation.utm_east : generateString()}
                                utm_n={!(this.state.EMPAttivo==1)?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    } else if (currstation.status == "Y") {
                        return <Marker position={
                                [currstation.latitude, currstation.longitude]
                            }
                            icon={(this.state.EMPAttivo==1)? IconNOISE : IconARMERIAGIALLO}
                            key={
                                currstation.stationname
                        }>
                            <Popup>{
                                <BasePopup titolo= {!this.state.EMPAttivo ? currstation.stationname : generateString()}
                                punteggioRosso={!this.state.EMPAttivo?currstation.score_R : generateString()}
                                punteggioBlu={!this.state.EMPAttivo?currstation.score_B : generateString()}
                                punteggioGiallo={!this.state.EMPAttivo?currstation.score_Y : generateString()}
                                minutesleft={!this.state.EMPAttivo?currstation.minutesleft : generateString()}
                                utm_z={!this.state.EMPAttivo?currstation.utm_zone : generateString()}
                                utm_e={!this.state.EMPAttivo?currstation.utm_east : generateString()}
                                utm_n={!this.state.EMPAttivo?currstation.utm_north : generateString()}/>
                            }</Popup>
                        </Marker>
                    }

                }

                else if (!(currstation.status.localeCompare("NF") && currstation.status.localeCompare("RF") && currstation.status.localeCompare("BF") && currstation.status.localeCompare("YF")))
                {
                  console.log("EMP:"+(this.state.EMPAttivo==1));

                  return <Marker position={
                          [currstation.latitude, currstation.longitude]
                      }
                      icon={(this.state.EMPAttivo==1)? IconNOISE : IconFire}
                      key={
                          currstation.stationname
                      }>
                          <Popup>{
                              <BasePopup titolo= {!(this.state.EMPAttivo==1) ? currstation.stationname : generateString()}
                              punteggioRosso={!(this.state.EMPAttivo==1) ? currstation.score_R : generateString()}
                              punteggioBlu={!(this.state.EMPAttivo==1) ? currstation.score_B : generateString()}
                              punteggioGiallo={!(this.state.EMPAttivo==1) ? currstation.score_Y : generateString()}
                              minutesleft={!(this.state.EMPAttivo==1) ? currstation.minutesleft : generateString()}
                              utm_z={!(this.state.EMPAttivo==1) ? currstation.utm_zone : generateString()}
                              utm_e={!(this.state.EMPAttivo==1) ? currstation.utm_east : generateString()}
                              utm_n={!(this.state.EMPAttivo==1) ? currstation.utm_north : generateString()}/>
                          }</Popup>
                      </Marker>

                }

                else if (!(currstation.status.localeCompare("RR")))
                {
                  console.log("EMP:"+(this.state.EMPAttivo==1));

                  return <Marker position={
                          [currstation.latitude, currstation.longitude]
                      }
                      icon={(this.state.EMPAttivo==1)? IconNOISE : IconREPAIRROSSO}
                      key={
                          currstation.stationname
                      }>
                          <Popup>{
                              <BasePopup titolo= {!(this.state.EMPAttivo==1) ? currstation.stationname : generateString()}
                              punteggioRosso={!(this.state.EMPAttivo==1) ? currstation.score_R : generateString()}
                              punteggioBlu={!(this.state.EMPAttivo==1) ? currstation.score_B : generateString()}
                              punteggioGiallo={!(this.state.EMPAttivo==1) ? currstation.score_Y : generateString()}
                              minutesleft={!(this.state.EMPAttivo==1) ? currstation.minutesleft : generateString()}
                              utm_z={!(this.state.EMPAttivo==1) ? currstation.utm_zone : generateString()}
                              utm_e={!(this.state.EMPAttivo==1) ? currstation.utm_east : generateString()}
                              utm_n={!(this.state.EMPAttivo==1) ? currstation.utm_north : generateString()}/>
                          }</Popup>
                      </Marker>

                }
                else if (!(currstation.status.localeCompare("BR")))
                {
                  console.log("EMP:"+(this.state.EMPAttivo==1));

                  return <Marker position={
                          [currstation.latitude, currstation.longitude]
                      }
                      icon={(this.state.EMPAttivo==1)? IconNOISE : IconREPAIRBLU}
                      key={
                          currstation.stationname
                      }>
                          <Popup>{
                              <BasePopup titolo= {!(this.state.EMPAttivo==1) ? currstation.stationname : generateString()}
                              punteggioRosso={!(this.state.EMPAttivo==1) ? currstation.score_R : generateString()}
                              punteggioBlu={!(this.state.EMPAttivo==1) ? currstation.score_B : generateString()}
                              punteggioGiallo={!(this.state.EMPAttivo==1) ? currstation.score_Y : generateString()}
                              minutesleft={!(this.state.EMPAttivo==1) ? currstation.minutesleft : generateString()}
                              utm_z={!(this.state.EMPAttivo==1) ? currstation.utm_zone : generateString()}
                              utm_e={!(this.state.EMPAttivo==1) ? currstation.utm_east : generateString()}
                              utm_n={!(this.state.EMPAttivo==1) ? currstation.utm_north : generateString()}/>
                          }</Popup>
                      </Marker>

                }
                else if (!(currstation.status.localeCompare("YR")))
                {
                  console.log("EMP:"+(this.state.EMPAttivo==1));

                  return <Marker position={
                          [currstation.latitude, currstation.longitude]
                      }
                      icon={(this.state.EMPAttivo==1)? IconNOISE : IconREPAIRGIALLO}
                      key={
                          currstation.stationname
                      }>
                          <Popup>{
                              <BasePopup titolo= {!(this.state.EMPAttivo==1) ? currstation.stationname : generateString()}
                              punteggioRosso={!(this.state.EMPAttivo==1) ? currstation.score_R : generateString()}
                              punteggioBlu={!(this.state.EMPAttivo==1) ? currstation.score_B : generateString()}
                              punteggioGiallo={!(this.state.EMPAttivo==1) ? currstation.score_Y : generateString()}
                              minutesleft={!(this.state.EMPAttivo==1) ? currstation.minutesleft : generateString()}
                              utm_z={!(this.state.EMPAttivo==1) ? currstation.utm_zone : generateString()}
                              utm_e={!(this.state.EMPAttivo==1) ? currstation.utm_east : generateString()}
                              utm_n={!(this.state.EMPAttivo==1) ? currstation.utm_north : generateString()}/>
                          }</Popup>
                      </Marker>

                }

                else if (!(currstation.status.localeCompare("NU")))
                {
                  console.log("EMP:"+(this.state.EMPAttivo==1));

                  return <Marker position={
                          [currstation.latitude, currstation.longitude]
                      }
                      icon={(this.state.EMPAttivo==1)? IconNOISE : IconNuclear}
                      key={
                          currstation.stationname
                      }>
                          <Popup>{
                              <BasePopup titolo= {!(this.state.EMPAttivo==1) ? currstation.stationname : generateString()}
                              punteggioRosso={!(this.state.EMPAttivo==1) ? currstation.score_R : generateString()}
                              punteggioBlu={!(this.state.EMPAttivo==1) ? currstation.score_B : generateString()}
                              punteggioGiallo={!(this.state.EMPAttivo==1) ? currstation.score_Y : generateString()}
                              minutesleft={!(this.state.EMPAttivo==1) ? currstation.minutesleft : generateString()}
                              utm_z={!(this.state.EMPAttivo==1) ? currstation.utm_zone : generateString()}
                              utm_e={!(this.state.EMPAttivo==1) ? currstation.utm_east : generateString()}
                              utm_n={!(this.state.EMPAttivo==1) ? currstation.utm_north : generateString()}/>
                          }</Popup>
                      </Marker>

                }
                else {
                    return <Marker position={
                            [currstation.latitude, currstation.longitude]
                        }
                        icon={IconPOI}
                        key={
                            currstation.stationname
                    }>
                        <Popup key={"pu_"+currstation.stationname}>{
                                <BasePopup key={"bpu_"+currstation.stationname} titolo= {!this.state.EMPAttivo ? currstation.stationname : generateString()}
                                punteggioRosso={!this.state.EMPAttivo?currstation.score_R : generateString()}
                                punteggioBlu={!this.state.EMPAttivo?currstation.score_B : generateString()}
                                punteggioGiallo={!this.state.EMPAttivo?currstation.score_Y : generateString()}
                                minutesleft={!this.state.EMPAttivo?currstation.minutesleft : generateString()}
                                utm_z={!this.state.EMPAttivo?currstation.utm_zone : generateString()}
                                utm_e={!this.state.EMPAttivo?currstation.utm_east : generateString()}
                                utm_n={!this.state.EMPAttivo?currstation.utm_north : generateString()}/>
                            }</Popup>
                    </Marker>
                }
            });



            var valore=Math.floor(this.state.heading/22,5);

            console.log('./images/user-icon-'+valore+'.png');

            const RotatedIconUser = new L.Icon({
                iconUrl: require('./images/user-icon-'+valore+'.png').default,
                iconAnchor: new L.Point(dimensionPOI / 2, dimensionPOI / 2),
                popupAnchor: [
                    0, - dimensionPOI / 2
                ],
                shadowUrl: null,
                shadowSize: null,
                shadowAnchor: null,
                iconSize: new L.Point(dimensionPOI*2, dimensionPOI*2),
                style:"transform: rotate(80deg);"
            });




            return <MapContainer

                whenCreated={ mapInstance => { this.mapRef.current = mapInstance } }
                bounds={mapbounds}
                boundsOptions={{padding: [50, 50]}}
                zoom={
                    this.state.zoom
                }
                style={
                    {
                        width: '100%',
                        height: window.innerHeight+130
                    }
                }>
                <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/> {listStations}
                {listItems}


                <Marker position={[this.state.latitude, this.state.longitude]}
                    icon={RotatedIconUser}
                    key={"you"}>
                </Marker>

                 </MapContainer>;
            }
            else
            {
                return <div>Loading</div>;
            }
    }
}

export default Ingame;
