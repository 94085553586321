import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import './GestisciPOI.css'; 
import ListaPOI from "./ListaPOI";
import AddIcon from '@material-ui/icons/Add';
import AggiungiPOI from "./AggiungiPOI";
import axios from 'axios';


class GestisciPOI extends React.Component 
{
    constructor(props) {
        super(props); 
        this.state =
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            elencoPOI:[],
            screen:"list"
        } 

        this.AggiungiPOI = this.AggiungiPOI.bind(this);
        this.handleNuovoPOI = this.handleNuovoPOI.bind(this);
  }


    componentDidMount()
    {
    }


    AggiungiPOI()
    {
        this.setState({
            screen:"add",
        });
    }

    handleNuovoPOI(msg)
    {
        this.setState({
            screen:"list"
        });
    }

    render()
    {
       if(this.state.screen==="list")
       {
       return <div>
           <div className="titoloSezione">GESTIONE POI</div>
           <ListaPOI IDGame={this.state.IDGame} Token={this.state.Token} />
           <AddIcon className="bottoneFloattante" style={{ fontSize: 60 }} onClick={this.AggiungiPOI}/>
           </div>
       }
       else if(this.state.screen==="add")
       {
       return <div>
           <div className="titoloSezione">CREA NUOVO POI</div>
           <AggiungiPOI IDGame={this.state.IDGame} Token={this.state.Token} callbackNuovoPOI={this.handleNuovoPOI} />
           </div>
       }

      };
}


export default GestisciPOI;