import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import './LogoHeader.css';



class LogoHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render()
    {
        return <div id="container_logo"></div>

    }
}

export default LogoHeader;
