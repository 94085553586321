import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import './CardGameAttuale.css';
import './StiliComuni.css'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import ButtonPT from './ButtonPT.js'
import ButtonCard from './ButtonCard.js'


//const labelSwitch = { inputProps: { 'aria-label': 'Switch demo' } };

class CardGameAttuale extends React.Component
{
    constructor(props) {
        super(props);
        this.state =
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            isPaused:this.props.isPaused,
        }
        this.MettiGiocoInPausa = this.MettiGiocoInPausa.bind(this);
        this.RiprendiGioco = this.RiprendiGioco.bind(this);
        this.cambiaStatoGame = this.cambiaStatoGame.bind(this);
        this.ResetGame = this.ResetGame.bind(this);
        this.NuovoGame = this.NuovoGame.bind(this);
    }

    RiprendiGioco()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;
        ParametriRichiesta.cmd = "R";

        axios.post('https://play.airsoftdigital.com/api/gamemngt.php', JSON.stringify(ParametriRichiesta))
        .then(response => {
            if(response.data.status==="OK")
            {
                this.setState(prevState => ({
                    isPaused:0}));

                this.props.callbackAggiornamentoStato(0);

                console.log(JSON.stringify("GIOCO RIPRESO"));
            }
            else
            {
                alert("Errore di connessione. ")
            }
        });
    }

    componentDidMount()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;

        axios.post('https://play.airsoftdigital.com/api/gamestatus.php', JSON.stringify(ParametriRichiesta)).then(response => {

            if (response.data.status === "OK") {
                this.setState({
                    isPaused:parseInt(response.data.isPaused),
                });
            } else {
                alert("Problemi di connessione. Impossibile recuperare lo stato del game.")
            }


        });
    }


    MettiGiocoInPausa()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;
        ParametriRichiesta.cmd = "P";

        axios.post('https://play.airsoftdigital.com/api/gamemngt.php', JSON.stringify(ParametriRichiesta))
        .then(response => {
            if(response.data.status==="OK")
            {
                this.setState(prevState => ({
                    isPaused:1}));
                this.props.callbackAggiornamentoStato(1);

                console.log(JSON.stringify("GIOCO IN PAUSA"));
            }
            else
            {
                alert("Errore di connessione. ")
            }
        });
    }


    cambiaStatoGame()
    {

      if(this.state.isPaused) this.setState(prevState => ({isPaused:0}));
      else this.setState(prevState => ({isPaused:1}));

          var ParametriRichiesta = new Object();
          ParametriRichiesta.token = this.state.Token;
          ParametriRichiesta.idgame = this.state.IDGame;

          if(this.state.isPaused) ParametriRichiesta.cmd = "R";
          else ParametriRichiesta.cmd = "P";

          axios.post('https://play.airsoftdigital.com/api/gamemngt.php', JSON.stringify(ParametriRichiesta))
          .then(response => {
              if(response.data.status==="OK")
              {
                this.props.callbackAggiornamentoStato(1);
              }
              else
              {
                  alert("Errore di connessione. ")
              }
          });
    }


    ResetGame()
    {
      var ParametriRichiesta = new Object();
      ParametriRichiesta.token = this.state.Token;
      ParametriRichiesta.idgame = this.state.IDGame;
      ParametriRichiesta.cmd = "Z";

      axios.post('https://play.airsoftdigital.com/api/gamemngt.php', JSON.stringify(ParametriRichiesta))
      .then(response => {
          if(response.data.status==="OK")
          {
              this.setState(prevState => ({
                  isPaused:1}));
              this.props.callbackAggiornamentoStato(1);

              console.log(JSON.stringify("Game resettato"));
          }
          else
          {
              alert("Errore di connessione. ")
          }
      });
    }

    NuovoGame()
    {
      var ParametriRichiesta = new Object();
      ParametriRichiesta.token = this.state.Token;
      ParametriRichiesta.idgame = this.state.IDGame;
      ParametriRichiesta.cmd = "N";

      axios.post('https://play.airsoftdigital.com/api/gamemngt.php', JSON.stringify(ParametriRichiesta))
      .then(response => {
          if(response.data.status==="OK")
          {
              this.setState(prevState => ({
                  isPaused:1}));
              this.props.callbackAggiornamentoStato(1);

              console.log(JSON.stringify("Nuovo Game"));
          }
          else
          {
              alert("Errore di connessione. ")
          }
      });
    }


    render()
    {

      console.log(this.state.isPaused);
       var ElControlloGame;
       var MessControlloGame;
       var Toggle="";

       if(this.state.isPaused==0)
       {
        ElControlloGame = <div><PauseCircleFilledIcon style={{ fontSize: 80}} onClick={this.MettiGiocoInPausa}/></div>
        MessControlloGame = <div className="titolettoCard TestoVerde">GAME ATTIVO</div>
        Toggle=<Switch  color="primary"  checked={true} size="big" onChange={this.cambiaStatoGame} />

        }
       else
       {
        ElControlloGame = <div><PlayCircleFilledWhiteIcon style={{ fontSize: 80}} onClick={this.RiprendiGioco}/></div>
        MessControlloGame = <div className="titolettoCard TestoGrigio">GAME IN PAUSA</div>
        Toggle=<div><Switch  color="primary"  checked={false} size="big" onChange={this.cambiaStatoGame} />
                   <br/>
                   <ButtonCard className="bottoneModaleAggiungi" testo="Reset" colore="verde" click={this.ResetGame}/>
                   <ButtonCard className="bottoneModaleAggiungi" testo="Nuovo Game" colore="verde" click={this.NuovoGame}/></div>

        }

       return <div>
       <div className="CardGenerica">
           <div className="testoPIN">PIN Team Rosso: <span className="TestoRosso">{this.props.pinR}</span></div>
           <div className="testoPIN">PIN Team Blu: <span className="TestoBlu">{this.props.pinB}</span></div>
           <div className="testoPIN">PIN Team Giallo: <span className="TestoGiallo">{this.props.pinY}</span></div>
           <hr></hr>

           {MessControlloGame}
           {Toggle}

       </div>
       <div></div>
       </div>
    };
}


export default CardGameAttuale;
