import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import './Login.css';
//import $ from "jquery"
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import ButtonPT from './ButtonPT';
import InputTextPT from './InputTextPT';
import PasswordTextPT from "./PasswordTextPT";
import axios from 'axios';


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.AccediAGame = this.AccediAGame.bind(this);
        this.LoginUtente = this.LoginUtente.bind(this);
        this.RegistraNuovoUtente = this.RegistraNuovoUtente.bind(this);

        this.state = { Token: "", nickname: "" };
    }



    VaiALoginUtente() {
        document.getElementById('ContainerUnisciti').classList.add("NonMostrare");
        document.getElementById('ContainerAccedi').classList.remove("NonMostrare");
        document.getElementById('ContainerCreaAccount').classList.add("NonMostrare");
    }

    VaiARegistraNuovoUtente() {
        document.getElementById('ContainerUnisciti').classList.add("NonMostrare");
        document.getElementById('ContainerAccedi').classList.add("NonMostrare");
        document.getElementById('ContainerCreaAccount').classList.remove("NonMostrare");
    }

    VaiAAccediAGame() {
        document.getElementById('ContainerUnisciti').classList.remove("NonMostrare");
        document.getElementById('ContainerAccedi').classList.add("NonMostrare");
        document.getElementById('ContainerCreaAccount').classList.add("NonMostrare");
    }


    AccediAGame = (e) => {
        e.preventDefault();
        var nickname = document.getElementById("nickname").value;
        var pin = document.getElementById("pinAccesso").value;

        if (nickname.length > 0 && pin.length > 0) {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.nickname = nickname;
            ParametriRichiesta.pin = pin;


            axios.post('https://play.airsoftdigital.com/api/joinoperator.php', JSON.stringify(ParametriRichiesta))
            .then(response => {
                if(response.data.status==="OK")
                {
                    console.log(JSON.stringify(response.data));
                    this.setState({Token : response.data.token});
                    this.props.callbackJoin(response.data); 
                }
                else
                {
                    alert("Pin di accesso non valido. Verificare con l'organizzatore.")
                }
            });
            
        }
        else {

        }
    }

    LoginUtente = (e) => {
        e.preventDefault();
        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;

        if (email.length > 0 && password.length > 0) 
        {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.email = email;
            ParametriRichiesta.password = password;

            axios.post('https://play.airsoftdigital.com/api/signuser.php', JSON.stringify(ParametriRichiesta))
            .then(response => {
                if(response.data.status==="OK")
                {
                    console.log(JSON.stringify(response.data));
                    this.setState({Token : response.data.token});
                    this.props.callbackLogin(response.data); 
                    
                    console.log(JSON.stringify(response.data));
                }
                else
                {
                    alert("email/password non valide. Verificare e riprovare.")
                }
            });
        }
        else
        {
            alert("Inserire email o username e password!");
        }
    }

    RegistraNuovoUtente() {

    }





    render() {

        return <div>
            <div className="ContainerCard" id="ContainerUnisciti">
                <h2 className="titoletto-form" >Unisciti a un game</h2>
                <InputTextPT testo="Nickname" id="nickname" />
                <InputTextPT testo="Pin di accesso game" id="pinAccesso" />
                <ButtonPT testo="Unisciti" click={this.AccediAGame} />
                <div className="Opzioni-Accesso"><a onClick={this.VaiALoginUtente}>Accedi</a> oppure <a onClick={this.VaiARegistraNuovoUtente}>Registrati</a></div>
            </div>
            <div className="ContainerCard NonMostrare" id="ContainerAccedi">
                <h2 className="titoletto-form" >Accedi al tuo account</h2>
                <InputTextPT testo="email/username" id="email" />
                <PasswordTextPT testo="Password" id="password" />
                <ButtonPT testo="Accedi" click={this.LoginUtente} />
                <div className="Opzioni-Accesso"><a onClick={this.VaiAAccediAGame}>Unisciti a un game</a> oppure <a onClick={this.VaiARegistraNuovoUtente}>Registrati</a></div>
            </div>
            <div className="ContainerCard NonMostrare" id="ContainerCreaAccount">
                <h2 className="titoletto-form" >Crea nuovo account</h2>
                <InputTextPT testo="Nickname" id="nickname" />
                <InputTextPT testo="email" id="email" />
                <InputTextPT testo="Password" id="password" />
                <InputTextPT testo="Conferma Password" id="password-conferma" />
                <ButtonPT testo="Registra Utente" click={this.RegistraNuovoUtente} />
                <div className="Opzioni-Accesso"><a onClick={this.VaiAAccediAGame}>Unisciti a un game</a> oppure <a onClick={this.VaiALoginUtente}>Accedi</a></div>
            </div>
            <div className="copyright"><a>&copy; Fabio Garigiola 2021</a></div>
        </div>

    };
}


export default Login;