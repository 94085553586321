import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import ButtonCard from "./ButtonCard";
import './AggiungiStazione.css'
import './StiliComuni.css'
import InputTextPT from './InputTextPT'
import SelectPT from './SelectPT'

class ModificaStazione extends React.Component
{
    constructor(props) {
        super(props);

        this.state=
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            serial:this.props.serial,
        }
        this.ConfermaModifica = this.ConfermaModifica.bind(this);
        this.AnnullaModifica = this.AnnullaModifica.bind(this);
      }

    AnnullaModifica()
    {
        this.props.callbackModificaStazione("KO");
    }

    ConfermaModifica()
    {
        //this.props.callbackModificaStazione("OK");
        var Nome="";
  
         if(document.getElementById("nomeStazione").value!="")
         {
             Nome = document.getElementById("nomeStazione").value;
         }
         else
         {
             Nome = document.getElementById("nomeStazione").placeholder;
         }

        var Icona = document.getElementById("icona").value;
        if(Nome!="")
        {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.token = this.state.Token;
            ParametriRichiesta.idgame = this.state.IDGame;
            ParametriRichiesta.stationserial = this.state.serial;
            ParametriRichiesta.stationname = Nome;
            ParametriRichiesta.icontype = Icona;
            ParametriRichiesta.cmd = "u";

            axios.post('https://play.airsoftdigital.com/api/stationmngt-v2.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.props.callbackModificaStazione("OK");
            }
            else
            {
                alert("Problemi di connessione o numero di serie della stazione non esistente.");
            }});
        }
        else
        {
            alert("Necessario inserire Numero di Serie e Nome della stazione!");
        }
    }



    render()
    {
       return <div className="CardGenerica">
       <div className="labelCard">Numero di Serie stazione</div>
       <div className="valoreCard"> {this.props.serial} </div>
       <div className="labelCard">Nome nuova stazione</div>
       <InputTextPT id="nomeStazione" testo={this.props.nome}/>
       <div className="labelCard">Icona </div>
       <SelectPT id="icona" opzioni={["Base","Ospedale","Armeria"]} />
       <div><ButtonCard className="bottoneModaleAggiungi" testo="Conferma" colore="verde" click={this.ConfermaModifica}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ButtonCard className="bottoneModaleAggiungi" testo="Annulla" click={this.AnnullaModifica}/></div>
       </div>
    };
}


export default ModificaStazione;
