import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import './BasePopup.css';



class BasePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titolo: this.props.titolo,
            scoreR: this.props.punteggioRosso,
            scoreB: this.props.punteggioBlu,
            scoreY: this.props.punteggioGiallo,
            utm_zone: this.props.utm_z,
            utm_east: this.props.utm_e,
            utm_north: this.props.utm_n,
            minutesleft:this.props.minutesleft
        }
    }

    render()
    {
        var minuti = parseInt(this.state.minutesleft);

        if(minuti>0)
        {
            return <div><div className="TitolettoPopup">{this.state.titolo}</div>
               <div className="MinutiAllaFine">{minuti} minuti alla riparazione</div>
               <div className="TitolettoCoordinate">Coordinate UTM</div>
                <div>Zone: <span className="TestoCoordinate">{this.props.utm_z}</span></div>
                <div>East: <span className="TestoCoordinate">{this.props.utm_e}</span></div>
                <div>North: <span className="TestoCoordinate">{this.props.utm_n}</span></div>
               </div>
        }
        else
        {
            return <div><div className="TitolettoPopup">{this.state.titolo}</div>
            
            <div className="TitolettoCoordinate">Coordinate UTM</div>
            <div>Zone: <span className="TestoCoordinate">{this.props.utm_z}</span></div>
            <div>East: <span className="TestoCoordinate">{this.props.utm_e}</span></div>
            <div>North: <span className="TestoCoordinate">{this.props.utm_n}</span></div>
            </div>
        }

    }
}

export default BasePopup;


//<div><div className="PunteggioRosso">{this.state.scoreR}</div><div className="PunteggioBlu">{this.state.scoreB}</div><div className="PunteggioGiallo">{this.state.scoreY}</div></div>
            