import React from "react";
import './InputTextPT.css';

class PasswordTextPT extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
        };
      }


    render(){
        return <input type="password" className="InputText-Base" id={this.props.id} placeholder={this.props.testo} onClick={this.props.onClick}></input>
    };
}


export default PasswordTextPT;