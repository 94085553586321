import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import './ListaStazioni.css';
import './StiliComuni.css'
import CardStazione from "./CardStazione";


class ListaStazioni extends React.Component 
{
    constructor(props) {
        super(props);  
        this.state=
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            stations:[]
        }
        this.ModificaStazione = this.ModificaStazione.bind(this);
      }


    ModificaStazione(msg)
    {
        this.props.callbackModificaStazione(msg);
    }


    componentDidMount()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;

        axios.post('https://play.airsoftdigital.com/api/stationstatus.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                
                console.log(JSON.stringify(response.data));

                this.setState(prevState => ({
                    stations:response.data.stations,
                    Token: prevState.Token,
                    IDGame:prevState.IDGame}));
            } else {
                alert("Problemi di connessione. Impossibile recuperare l'elenco delle stazioni associate all'account.")
            }
        });
    }

    render()
    {
        var Stazioni = this.state.stations;
        const ListaStazioniAssociate = Stazioni.map((currStation) =>
            <CardStazione callbackModificaStazione={this.ModificaStazione} IDGame={this.state.IDGame} Token={this.state.Token} nome={currStation.nome} seriale={currStation.serial} attiva={currStation.isActive} icona={currStation.icon} latitudine={currStation.latitude} longitudine={currStation.longitude} ultimoUpdate={currStation.lastupdate} utm_zone={currStation.utm_zone} utm_east={currStation.utm_east} utm_north={currStation.utm_north}/>
        );

       return <div>
        {ListaStazioniAssociate}
        <div style={{height:70}}></div>
       </div>
    };
}


export default ListaStazioni;