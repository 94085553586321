import logo from './logo.svg';
import './App.css';
import Login from './Login';
import Ingame from './Ingame'
import React, { useEffect, useState } from "react";
import AppMenu from './AppMenu';
import LogoHeader from './LogoHeader';
import ScorePanel from "./ScorePanel";
import GestisciStazioni from './GestisciStazioni';
import VediTeam from './VediTeam';
import GestisciPOI from './GestisciPOI';
import { getToken, onMessageListener } from './firebase';
import { SettingsBackupRestoreRounded } from '@material-ui/icons';
import axios from 'axios';
import { isUndefined } from 'lodash';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import AirplaneIcon from '@material-ui/icons/Flight';
import firebase from 'firebase';


import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';



var TokenSaved = false;
var elencoObiettivi = <div></div>;



export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  Stations: Object;
}


function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open , Stations, attivaAirStrike} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {

  };

  let elencoStazioni = "";

  const getImageSrc = (station) => {
    console.log("icon:" + station.icon + "  status:" + station.status);
    console.log("comparazione: " + station.status.localeCompare("R"));


    if (station.icon == 1 && station.status == "N") return "./images/flag-neutral.png";
    else if (station.icon == 1 && station.status == "R") return "./images/flag-red.png";
    else if (station.icon == 1 && station.status == "B") return "./images/flag-blue.png";
    else if (station.icon == 1 && station.status == "Y") return "./images/flag-yellow.png";

    else if (station.icon == 2 && station.status == "N") return "./images/luggage-neutral.png";
    else if (station.icon == 2 && station.status == "R") return "./images/luggage-red.png";
    else if (station.icon == 2 && station.status == "B") return "./images/luggage-blue.png";
    else if (station.icon == 2 && station.status == "Y") return "./images/luggage-yellow.png";

    else if (station.icon == 3 && station.status == "N") return "./images/target-neutral.png";
    else if (station.icon == 3 && station.status == "R") return "./images/target-red.png";
    else if (station.icon == 3 && station.status == "B") return "./images/target-blue.png";
    else if (station.icon == 3 && station.status == "Y") return "./images/target-yellow.png";

    else if (station.icon == 3 && station.status == "N") return "./images/target-neutral.png";
    else if (station.icon == 3 && station.status == "R") return "./images/target-red.png";
    else if (station.icon == 3 && station.status == "B") return "./images/target-blue.png";
    else if (station.icon == 3 && station.status == "Y") return "./images/target-yellow.png";

    else if (!station.status.localeCompare("NF")) return "./images/fire.png";
    else if (!station.status.localeCompare("RF")) return "./images/fire.png";
    else if (!station.status.localeCompare("BF")) return "./images/fire.png";
    else if (!station.status.localeCompare("YF")) return "./images/fire.png";

    else if (!station.status.localeCompare("RR")) return "./images/repair_rosso.png";
    else if (!station.status.localeCompare("BR")) return "./images/repair_blu.png";
    else if (!station.status.localeCompare("YR")) return "./images/repair_giallo.png";


    console.log("comparazione: " + station.status == "RF");

    return null;
  };



  const getObjList = (sStations) => {
    if(sStations){
    return sStations.map((station) => (
          <ListItem disableGutters key={station.serial}>
            <ListItemButton onClick={() => attivaAirStrike(station.serial)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  {<img src={getImageSrc(station)} alt="Station Icon" />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={station.stationname} />
            </ListItemButton>
          </ListItem>
        ))}
    else return "";
  };




  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Imposta target</DialogTitle>
      <List sx={{ pt: 0 }}>
      {getObjList(Stations)}
      </List>
      <DialogActions>
          <Button onClick={handleClose}>Annulla Attacco</Button>
      </DialogActions>
    </Dialog>
  );
}



function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


function deleteCookie(cname) {
  setCookie(cname,"",-1);
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}





class App extends React.Component {

  constructor(props) {
    super(props);


    this.CambiaSchermata = this.CambiaSchermata.bind(this);
    this.AggiornaPunteggio = this.AggiornaPunteggio.bind(this);
    this.AttivaEMP = this.AttivaEMP.bind(this);
    this.AttivaAirStrike = this.AttivaAirStrike.bind(this);


    this.state =
    {
      Token: "",
      IDGame:"",
      screen: "map",
      scoreR:0,
      scoreB:0,
      scoreY:0,
      tipoUtente:"",
      pinR:"",
      pinB:"",
      pinY:"",
      isActive:0,
      isPaused:0,
      TokenPush:"",
      EMP:0,
      AirStrike:0,
      openDialog:false,
    };

    if(getCookie("Token")!="" && getCookie("IDGame")!="")
    {
      this.state =
      {
      Token: getCookie("Token"),
      IDGame: getCookie("IDGame"),
      screen: "map",
      scoreR:0,
      scoreB:0,
      scoreY:0,
      tipoUtente:getCookie("TipoUtente"),
      pinR:getCookie("pinR"),
      pinB:getCookie("pinB"),
      pinY:getCookie("pinY"),
      isActive:getCookie("isActive"),
      isPaused:getCookie("isPaused"),
      EMP:0,
      AirStrike:0
      };
    }

    this.handleJoinGame = this.handleJoinGame.bind(this);
    this.handleLoginGame = this.handleLoginGame.bind(this);
    this.SaveToken = this.SaveToken.bind(this);


    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.chiudiDialogAirStrike = this.chiudiDialogAirStrike.bind(this);
  }




  handleClickOpen = () => {
    this.setState(prevState => ({
      openDialog:true
    }));
  };



  chiudiDialogAirStrike()
  {
    console.log("chiusa");
    this.setState(prevState => ({
      openDialog:false
    }));
  }



  AttivaEMP()
  {
    if(window.confirm("Satelliti pronti per l'attacco EMP. Confermate?"))
    {
    var ParametriRichiesta = new Object();
    ParametriRichiesta.token = this.state.Token;
    ParametriRichiesta.idgame = this.state.IDGame;
    ParametriRichiesta.bonus = "EMP";

    axios.post('https://play.airsoftdigital.com/api/activatebonus.php', JSON.stringify(ParametriRichiesta)).then(response => {

        if (response.data.status === "OK")
        {
          alert("Attacco EMP Confermato.");
          this.setState(
            {
              EMP:0,
            }
          );
        }
      });
    }
  }


  AttivaAirStrike(serial)
  {
    var ParametriRichiesta = new Object();
    ParametriRichiesta.token = this.state.Token;
    ParametriRichiesta.idgame = this.state.IDGame;
    ParametriRichiesta.bonus = "AS";
    ParametriRichiesta.target = serial;


    axios.post('https://play.airsoftdigital.com/api/activatebonus.php', JSON.stringify(ParametriRichiesta)).then(response => {

        if (response.data.status === "OK")
        {
          alert("Airstrike Confermato.");
          this.setState(
            {
              AirStrike:0,
              openDialog:false,

            }
          );
        }
      });

      this.setState(
        {
          openDialog:false,
        });
  }





  AggiornaPunteggio(sR, sB, sY, sEMP, sAirStrike, sStations)
  {

    this.setState(prevState => ({
      Token: prevState.Token,
      screen: prevState.screen,
      IDGame: prevState.IDGame,
      scoreR: sR,
      scoreB: sB,
      scoreY: sY,
      tipoUtente: prevState.tipoUtente,
      pinR:prevState.pinR,
      pinB:prevState.pinB,
      pinY:prevState.pinY,
      isActive:prevState.isActive,
      isPaused:prevState.isPaused,
      EMP:sEMP,
      AirStrike:sAirStrike,
      Stations:sStations,


  }));



  }

  SaveToken(msg)
  {
    this.setState({
      TokenPush:msg,
    });
  }

  CambiaSchermata(nScreen)
  {

    if(nScreen==="map")
    {
      this.setState(prevState => ({
        Token: prevState.Token,
        screen: "map"
    }));
    }
    else if(nScreen==="gam")
    {
      this.setState(prevState => ({
        Token: prevState.Token,
        screen: "gam"
    }));
    }
    else if(nScreen==="poi")
    {
      this.setState(prevState => ({
        Token: prevState.Token,
        screen: "poi"
    }));
    }
    else if(nScreen==="team")
    {
      this.setState(prevState => ({
        Token: prevState.Token,
        screen: "team"
    }));
    }
    else if(nScreen==="exit")
    {
      if(window.confirm("Stai abbandonando il game. Vuoi confermare?"))
      {
        this.setState(prevState => ({
          Token: "",
          screen: "",
          IDGame: "",
          scoreR: 0,
          scoreB: 0,
          scoreY: 0,
          tipoUtente:"",
          pinR:"",
          pinB:"",
          pinY:"",
          isActive:0
        }));

        deleteCookie("Token");
        deleteCookie("IDGame");
        deleteCookie("TipoUtente");
        deleteCookie("pinR");
        deleteCookie("pinB");
        deleteCookie("pinY");
        deleteCookie("isActive");
      }
  }
}


  handleJoinGame(msg) {
    this.setState({
      Token: msg.token,
      IDGame: msg.idgame,
      screen: "map",
      scoreR:0,
      scoreB:0,
      scoreY:0,
      pinR:"",
      pinB:"",
      pinY:"",
      tipoUtente:"u",
      isActive:msg.isActive,
      isPaused:msg.isPaused,
    });
    setCookie("Token", msg.token, 3);
    setCookie("IDGame", msg.idgame, 3);
    setCookie("TipoUtente","u",3);
    setCookie("isActive",msg.isActive,3);
    setCookie("isPaused",msg.isPaused,3);
  }

  handleLoginGame(msg) {
    this.setState({
      Token: msg.token,
      IDGame: msg.idgame,
      screen: "map",
      scoreR:0,
      scoreB:0,
      scoreY:0,
      pinR:msg.pinR,
      pinB:msg.pinB,
      pinY:msg.pinY,
      tipoUtente:"a",
      isActive:msg.isActive,
      isPaused:msg.isPaused,
    });
    setCookie("Token", msg.token, 3);
    setCookie("IDGame", msg.idgame, 3);
    setCookie("TipoUtente","a",3);
    setCookie("pinR",msg.pinR,3);
    setCookie("pinB",msg.pinB,3);
    setCookie("pinY",msg.pinY,3);
    setCookie("isActive",msg.isActive,3);
    setCookie("isPaused",msg.isPaused,3);
  }

  componentDidMount()
  {

   //this.setState({Token:"ss"});
  }


  render()
  {
    if (this.state.Token == "") {
      return (
        <div className="App">
          <header className="App-header">
            <img src="logo-patriot-100.png"></img>
            <Login callbackJoin={this.handleJoinGame} callbackLogin={this.handleLoginGame} nome="Login" />
          </header>
        </div>
      );
    }
    else {
      if(this.state.screen==="map")
      {


        var bottoneEMP;
        var DObiettivi="";

        if(this.state.EMP==1)
        {
          console.log("EMP ATTIVO");
          bottoneEMP = <div id="BottoneEMP" onClick={this.AttivaEMP}><BlurOnIcon style={{ fontSize: 45 }} /><div>EMP</div></div>
        }

        var bottoneAirStrike;
        if(this.state.AirStrike==1)
        {
          console.log("AirStrike Attivo");
          bottoneAirStrike = <div id="BottoneAirStrike" onClick={this.handleClickOpen}><AirplaneIcon style={{ fontSize: 45 }} /><div>AirStrike</div></div>
        }


        if(!TokenSaved)
        {
          let messaging = null;
          if (firebase.messaging.isSupported())
          {
            getToken(this.SaveToken, this.state.Token, this.state.IDGame);
          }
          TokenSaved=true;

        }




      return (
        <div className="App">
          <header className="App-header">
          <ScorePanel RedScore={this.state.scoreR} BlueScore={this.state.scoreB} YellowScore={this.state.scoreY} />
          <Ingame Token={this.state.Token} IDGame={this.state.IDGame} AggiornaPunteggio={this.AggiornaPunteggio}/>
          {bottoneEMP}
          {bottoneAirStrike}

          <SimpleDialog
            selectedValue={0}
            open={this.state.openDialog}
            onClose={this.chiudiDialogAirStrike}
            attivaAirStrike={this.AttivaAirStrike}
            Stations={this.state.Stations}
          />


          <AppMenu callback={this.CambiaSchermata} tipoUtente={this.state.tipoUtente}/>
          <LogoHeader/>


          </header>
        </div>
      );
    }
    else
      if(this.state.screen==="gam")
      {

      return (
        <div className="App">
          <header className="App-header">
          <GestisciStazioni Token={this.state.Token} IDGame={this.state.IDGame} isPaused={this.state.isPaused} pinR={this.state.pinR} pinB={this.state.pinB} pinY={this.state.pinY} />
          <AppMenu callback={this.CambiaSchermata} tipoUtente={this.state.tipoUtente}/>
          </header>
        </div>
      );
    }
    else
    if(this.state.screen==="poi")
    {
    return (
      <div className="App">
        <header className="App-header">
        <GestisciPOI Token={this.state.Token} IDGame={this.state.IDGame} />
        <AppMenu callback={this.CambiaSchermata} tipoUtente={this.state.tipoUtente}/>
        </header>
      </div>
    );
  }
  else if(this.state.screen==="team")
  {
    return (
      <div className="App">
        <header className="App-header">
        <VediTeam Token={this.state.Token} IDGame={this.state.IDGame} />
        <AppMenu callback={this.CambiaSchermata} tipoUtente={this.state.tipoUtente}/>
        </header>
      </div>
    )
  }
    else
    {
      return (
        <div className="App">
          <header className="App-header">
          Not Ready
          <AppMenu callback={this.CambiaSchermata} tipoUtente={this.state.tipoUtente}/>
          <LogoHeader/>
          </header>
        </div>
      );
    }
    }
  }
}

export default App;
