import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import ButtonCard from "./ButtonCard";
import './AggiungiStazione.css'
import './StiliComuni.css'
import InputTextPT from './InputTextPT' 
import SelectPT from './SelectPT'

class AggiungiStazione extends React.Component 
{
    constructor(props) {
        super(props);  

        this.state=
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
        }
        this.ConfermaInserimento = this.ConfermaInserimento.bind(this);
        this.AnnullaInserimento = this.AnnullaInserimento.bind(this);
      }

    AnnullaInserimento()
    {
        this.props.callbackAggiungiStazione("KO");
    }

    ConfermaInserimento()
    {
        //this.props.callbackAggiungiStazione("OK");
        var NumeroSerie = document.getElementById("serialNumber").value;
        var Nome = document.getElementById("nomeStazione").value;
        var Icona = document.getElementById("icona").value;
        if(NumeroSerie!="" && Nome!="")
        {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.token = this.state.Token;
            ParametriRichiesta.idgame = this.state.IDGame;
            ParametriRichiesta.stationserial = NumeroSerie;
            ParametriRichiesta.stationname = Nome;
            ParametriRichiesta.icontype = Icona;
            ParametriRichiesta.cmd = "n";

            axios.post('https://play.airsoftdigital.com/api/stationmngt-v2.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.props.callbackAggiungiStazione("OK");
            }
            else
            {
                alert("Problemi di connessione o numero di serie della stazione non esistente.");
            }});
        }
        else
        {
            alert("Necessario inserire Numero di Serie e Nome della stazione!");
        }
    }



    render()
    {
       return <div className="CardGenerica"> 
       <div className="labelCard">Numero di Serie stazione</div>
       <InputTextPT id="serialNumber"/>
       <div className="labelCard">Nome nuova stazione</div>
       <InputTextPT id="nomeStazione" />
       <div className="labelCard">Icona </div>
       <SelectPT id="icona" opzioni={["Base","Valigia","Bersaglio"]} />
       <div><ButtonCard className="bottoneModaleAggiungi" testo="Conferma" colore="verde" click={this.ConfermaInserimento}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ButtonCard className="bottoneModaleAggiungi" testo="Annulla" click={this.AnnullaInserimento}/></div>
       </div>
    };
}


export default AggiungiStazione;