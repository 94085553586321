import React from "react";
import './SelectPT.css';

class SelectPT extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            opzioni:this.props.opzioni,
        };
      }


    render(){
        var i = 1;
        const ListaOpzioni = this.state.opzioni.map((currOption) =>
            <option value={i++} key={currOption}>{currOption}</option>
         );
        return <select className="Select-Base" id={this.props.id}>
            {ListaOpzioni}
        </select>    };
}


export default SelectPT;