import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import './VediTeam.css'; 
import axios from 'axios';
import ListaOperatori from "./ListaOperatori";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



class VediTeam extends React.Component 
{
    constructor(props) {
        super(props); 
        this.state =
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,

        } 
  }


    componentDidMount()
    {
    }



    render()
    {
       return <div>
           <div className="titoloSezione">Operatori Collegati</div>
           <div className="TestoRosso">RED TEAM</div>
           <ListaOperatori operatori="['pippo', 'pluto', 'paperino']" />           
           <div className="TestoBlu">BLUE TEAM</div>
           <ListaOperatori operatori="['pippo', 'pluto', 'paperino']" />
           <div className="TestoGiallo">YELLOW TEAM</div>
           <ListaOperatori operatori="['pippo', 'pluto', 'paperino']" />
               
           </div>
      };
}


export default VediTeam;