import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import CardGameAttuale from "./CardGameAttuale";
import './GestisciStazioni.css';
import ListaStazioni from "./ListaStazioni";
import AddIcon from '@material-ui/icons/Add';
import AggiungiStazione from "./AggiungiStazione";
import ModificaStazione from "./ModificaStazione";
import axios from 'axios';
import CardRegoleGame from "./CardRegoleGame";


class GestisciStazioni extends React.Component 
{
    constructor(props) {
        super(props); 
        this.state =
        {
            pinR:this.props.pinR,
            pinB:this.props.pinB,
            pinY:this.props.pinY,
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            elencoStazioni:[],
            isPaused:0,
            screen:"list",
            serialStazioneDaModificare:"",
            iconStazioneDaModificare:"",
            NomeStazioneDaModificare:"",
        } 

        this.AggiornaElencoStazioni = this.AggiornaElencoStazioni.bind(this);
        this.CallbackAggiornaStatoGame = this.CallbackAggiornaStatoGame.bind(this);
        this.AggiungiStazione = this.AggiungiStazione.bind(this);
        this.ModificaStazione = this.ModificaStazione.bind(this);
        this.handleAggiungiStazione = this.handleAggiungiStazione.bind(this);
    }


    AggiornaElencoStazioni()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.props.Token;
        ParametriRichiesta.idgame = this.props.IDGame;
    }

    componentDidMount()
    {
        this.AggiornaElencoStazioni();
        
    }

    CallbackAggiornaStatoGame(NewIsPaused)
    {
        this.setState(
            {
                isPaused:NewIsPaused,
            }
        );
    }

    AggiungiStazione()
    {
        this.setState(
        {
            screen:"add",
        });
    }

    ModificaStazione(msg)
    {
        this.setState(
            {
                screen:"edit",
                serialStazioneDaModificare:msg.serialnumber,
                iconStazioneDaModificare:msg.icon,
                NomeStazioneDaModificare:msg.name,
            }
        );
    }


    handleAggiungiStazione(msg)
    {   
        this.setState(
            {
                screen:"list",
                serialStazioneDaModificare:"",
                iconStazioneDaModificare:"",
                NomeStazioneDaModificare:"",

            }
        );
    }


    ModificaStazione(msg)
    {
        this.setState({
            serialStazioneDaModificare:msg.serial,
            iconStazioneDaModificare:msg.icon,
            NomeStazioneDaModificare:msg.nome,
            screen:"edit"
        });
    }



    render()
    {
       if(this.state.screen==="list")
       {
       return <div>
           <div className="titoloSezione">GESTIONE GAME</div>
           <CardGameAttuale callbackAggiornamentoStato={this.CallbackAggiornaStatoGame} pinR={this.state.pinR} pinB={this.state.pinB} pinY={this.state.pinY} Token={this.state.Token} IDGame={this.state.IDGame}/>
           <div className="titoloSezione">REGOLE GAME</div>
           <CardRegoleGame Token={this.state.Token} IDGame={this.state.IDGame}/>
           <div className="titoloSezione">STAZIONI ASSOCIATE</div>
           <ListaStazioni Token={this.state.Token} IDGame={this.state.IDGame} callbackModificaStazione={this.ModificaStazione} />
           <AddIcon className="bottoneFloattante" style={{ fontSize: 60 }} onClick={this.AggiungiStazione}/>
      
       </div>
       }
       else if(this.state.screen==="add")
       {
        return <div>
            <div className="titoloSezione">REGISTRA NUOVA STAZIONE</div>
            <AggiungiStazione Token={this.state.Token} IDGame={this.state.IDGame} callbackAggiungiStazione={this.handleAggiungiStazione}/>
         </div>
        }
        else if(this.state.screen==="edit")
       {
        return <div>
            <div className="titoloSezione">MODIFICA STAZIONE</div>
            <ModificaStazione Token={this.state.Token} IDGame={this.state.IDGame} serial={this.state.serialStazioneDaModificare} nome={this.state.NomeStazioneDaModificare} icon={this.state.iconStazioneDaModificare} callbackModificaStazione={this.handleAggiungiStazione} />
         </div>
        }
    };
}


export default GestisciStazioni;