import React from "react";
import './InputTextPT.css';

class InputTextPT extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
          //testo:this.props.testo
        };
      }


    render(){
        return <input className="InputText-Base" type="text"  id={this.props.id} placeholder={this.props.testo} onClick={this.props.onClick}></input>
    };
}


export default InputTextPT;
