import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import './StiliComuni.css'
import './CardPOI.css'
import ButtonPT from "./ButtonPT";
import ButtonCard from "./ButtonCard";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';



class CardPOI extends React.Component 
{
    constructor(props) {
        super(props);  

        this.CancellaPOI = this.CancellaPOI.bind(this);
        this.ModificaPOI = this.ModificaPOI.bind(this);
        

        this.state=
        {
            nome:this.props.nome,
            latitudine:this.props.latitudine,
            longitudine:this.props.longitudine,
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            utm_zone:this.props.utm_zone,
            utm_east:this.props.utm_east,
            utm_north:this.props.utm_north,
            id:this.props.id,
            
        }
      }



    DisattivaPOI()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;
        ParametriRichiesta.id = this.state.id;
        ParametriRichiesta.cmd = "d";

        axios.post('https://play.airsoftdigital.com/api/stationmngt-v2.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.setState({attiva:0});
            }
        });
    }



    ModificaPOI()
    {
   

    }

   
    CancellaPOI()
    {
        if(window.confirm("Stai per eliminare questo POI. Vuoi procedere?"))
        {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.token = this.state.Token;
            ParametriRichiesta.idgame = this.state.IDGame;
            ParametriRichiesta.id = this.state.id;
            ParametriRichiesta.cmd = "d";

            axios.post('https://play.airsoftdigital.com/api/poimngt.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.props.callbackModificaPOI("OK");
            }
            else
            {
                alert("Problemi di connessione. Impossibile recuperare l'elenco delle stazioni associate all'account.");
            }
        });
        }
    }

    render()
    {
   
     
        return <div className="CardGenerica"> 
        
        <div className="bodyDxCard"><div className="titolettoCard">{this.state.nome}</div>
        <hr />
        <div className="labelCard">Coordinate (polari)</div>
        <div className="valoreCard"> Lat. {this.state.latitudine}</div>
        <div className="valoreCard"> Lng. {this.state.longitudine}</div>
 
        <div className="labelCard">Coordinate (UTM)</div>
        <div className="valoreCard"> Zone {this.state.utm_zone}</div>
        <div className="valoreCard"> East {this.state.utm_east}</div>
        <div className="valoreCard"> North {this.state.utm_north}</div>
 
        </div>
        <div className="spallaSxCard"><DeleteIcon className="BottoneAzione" style={{ fontSize: 40 }} onClick={this.CancellaPOI}/></div>
     
        </div>

       /* 
       return <div className="CardGenerica"> 
        
       <div className="bodyDxCard"><div className="titolettoCard">{this.state.nome}</div>
       <hr />
       <div className="labelCard">Coordinate (polari)</div>
       <div className="valoreCard"> Lat. {this.state.latitudine}</div>
       <div className="valoreCard"> Lng. {this.state.longitudine}</div>

       <div className="labelCard">Coordinate (UTM)</div>
       <div className="valoreCard"> Zone {this.state.utm_zone}</div>
       <div className="valoreCard"> East {this.state.utm_east}</div>
       <div className="valoreCard"> North {this.state.utm_north}</div>

       </div>
       <div className="spallaSxCard"><EditIcon  onClick={this.ModificaPOI} className="BottoneAzione" style={{ fontSize: 40 }}/> <DeleteIcon className="BottoneAzione" style={{ fontSize: 40 }} onClick={this.CancellaPOI}/></div>
    
       </div>*/
    };
}


export default CardPOI;