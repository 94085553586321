import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import './StiliComuni.css'
import './CardStazione.css'
import ButtonPT from "./ButtonPT";
import ButtonCard from "./ButtonCard";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';




function isStringTimestampWithinLast5Minutes(timeString) {
    // Converte la stringa di tempo in un oggetto Date
    const timestampDate = new Date(timeString);
    // Converte l'oggetto Date in timestamp (millisecondi)
    const timestamp = timestampDate.getTime();
    // Ottiene il timestamp attuale
    const now = Date.now();
    // Calcola la differenza in millisecondi tra il timestamp attuale e quello convertito
    const diff = now - timestamp;
    // 5 minuti in millisecondi
    const fiveMinutes = 5 * 60 * 1000;
    // Ritorna true se la differenza è minore di 5 minuti, false altrimenti
    return diff < fiveMinutes;
}



class CardStazione extends React.Component
{
    constructor(props) {
        super(props);

        this.DisattivaStazione = this.DisattivaStazione.bind(this);
        this.AttivaStazione = this.AttivaStazione.bind(this);
        this.CancellaStazione = this.CancellaStazione.bind(this);
        this.ModificaStazione = this.ModificaStazione.bind(this);


        this.state=
        {
            nome:this.props.nome,
            serial:this.props.seriale,
            latitudine:this.props.latitudine,
            longitudine:this.props.longitudine,
            icona:this.props.icona,
            attiva:this.props.attiva,
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            ultimoUpdate:this.props.ultimoUpdate,
            utm_zone:this.props.utm_zone,
            utm_east:this.props.utm_east,
            utm_north:this.props.utm_north,

        }
      }



    DisattivaStazione()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.Token;
        ParametriRichiesta.idgame = this.state.IDGame;
        ParametriRichiesta.stationserial = this.state.serial;
        ParametriRichiesta.cmd = "d";

        axios.post('https://play.airsoftdigital.com/api/stationmngt-v2.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.setState({attiva:0});
            }
        });
    }


    AttivaStazione()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.token = this.state.token;
        ParametriRichiesta.idgame = this.state.idgame;
        ParametriRichiesta.stationserial = this.state.serial;
        ParametriRichiesta.cmd = "a";

        axios.post('https://play.airsoftdigital.com/api/stationmngt-v2.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.setState({attiva:1});
            }
        });
    }

    ModificaStazione()
    {
        var ParametriRichiesta = new Object();
        ParametriRichiesta.serial = this.state.serial;
        ParametriRichiesta.icon = this.state.icona;
        ParametriRichiesta.nome = this.state.nome;

        this.props.callbackModificaStazione(ParametriRichiesta);

    }


    CancellaStazione()
    {
        if(window.confirm("Stai per rimuovere questa staziome dal tuo account. Vuoi procedere?"))
        {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.token = this.state.token;
            ParametriRichiesta.idgame = this.state.idgame;
            ParametriRichiesta.stationserial = this.state.serial;
            ParametriRichiesta.cmd = "dlt";

            axios.post('https://play.airsoftdigital.com/api/stationmngt-v2.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.setState({attiva:1});
            }
            else
            {
                alert("Problemi di connessione. Impossibile recuperare l'elenco delle stazioni associate all'account.");
            }
        });
        }
    }

    render()
    {
       var iconaStazione;
       var BottoneGestioneStazione;
       var NomeBase;
       var classeCard;

       if(this.state.icona==1)
       {
        iconaStazione = <img src='./images/flag-neutral.png' />;
       }
       else if(this.state.icona==2)
       {
        iconaStazione = <img src='./images/ospedale-neutral.png' />;
       }
       else if(this.state.icona==3)
       {
        iconaStazione = <img src='./images/armeria-neutral.png' />;
       }

       if(this.state.attiva==1)
       {
        //NomeBase=<div className="titolettoCard">{this.state.nome}&nbsp;&nbsp;<div className="PallinaVerde"></div></div>;
        NomeBase=<div className="titolettoCard">{iconaStazione}&nbsp;&nbsp;&nbsp;{this.state.nome}</div>;
        BottoneGestioneStazione = <ButtonCard testo="Disattiva" colore="verde" click={this.DisattivaStazione}/>;
       }
       else
       {
        //NomeBase=<div className="titolettoCard">{this.state.nome}&nbsp;&nbsp;<div className="PallinaRossa"></div></div>;
        NomeBase=<div className="titolettoCard">{iconaStazione}&nbsp;&nbsp;&nbsp;{this.state.nome}</div>;
        BottoneGestioneStazione = <ButtonCard testo="Attiva" click={this.AttivaStazione}/>;
       }

       const updateDate = new Date(this.state.ultimoUpdate);
       classeCard = "CardGenerica";

       if(!isStringTimestampWithinLast5Minutes(updateDate))
       {
         classeCard = "CardGenericaRed";
       }

       return <div className={classeCard}>

       <div className="bodyDxCard">{NomeBase}
       <hr />
       <div className="labelCard">Numero Seriale</div>
       <div className="valoreCard"> {this.state.serial}</div>
       <div className="labelCard">Coordinate (polari)</div>
       <div className="valoreCard"> Lat. {this.state.latitudine}</div>
       <div className="valoreCard"> Lng. {this.state.longitudine}</div>

       <div className="labelCard">Coordinate (UTM)</div>
       <div className="valoreCard"> Zone {this.state.utm_zone}</div>
       <div className="valoreCard"> East {this.state.utm_east}</div>
       <div className="valoreCard"> North {this.state.utm_north}</div>


       <div className="labelCard">Ultimo Aggiornamento</div>
       <div className="valoreCard"> {this.state.ultimoUpdate}</div>
      {BottoneGestioneStazione}
       </div>
       <div className="spallaSxCard"><EditIcon  onClick={this.ModificaStazione} className="BottoneAzione" style={{ fontSize: 40 }}/> <DeleteIcon className="BottoneAzione" style={{ fontSize: 40 }} onClick={this.CancellaStazione}/></div>

       </div>
    };
}


export default CardStazione;
