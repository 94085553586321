import { Button } from "@material-ui/core";
import React from "react";
import './ButtonCard.css';

class ButtonCard extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
        };
      }


    render(){

        if(this.props.colore==="verde")
        {
        return <button className="BottoneCardVerde" id={this.props.id} onClick={this.props.click}>
            {this.props.testo}
        </button>
        }
        else
        {
            return <button className="BottoneCardRosso" id={this.props.id} onClick={this.props.click}>
            {this.props.testo}
        </button>
        }
    };
}


export default ButtonCard;