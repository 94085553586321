import React from "react";
import './VistaDisturbata.css';

class VistaDisturbata extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
        };
      }


    render(){
        
        return <div className="SchermoDisturbato">
            SYSTEM JAMMED
        </div>    };
}


export default VistaDisturbata;