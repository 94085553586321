import firebase from 'firebase'
import 'firebase/messaging';
import axios from 'axios';


  const firebaseConfig = {
    apiKey: "AIzaSyC6kLO-swgyjxT2OUIVbZIIEjgIEPTR40U",
    authDomain: "airsoft-digital-battlefield.firebaseapp.com",
    databaseURL: "https://airsoft-digital-battlefield.firebaseio.com",
    projectId: "airsoft-digital-battlefield",
    storageBucket: "airsoft-digital-battlefield.appspot.com",
    messagingSenderId: "921083996215",
    appId: "1:921083996215:web:d29bc4d871e9c852c437b2"
  };

  if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  }else {
      firebase.app(); // if already initialized, use that one
  }


let messaging = null;
if (firebase.messaging.isSupported())
{
    messaging = firebase.messaging();
}


  export const getToken = (setTokenFound, Token, IDGame) => {
    return messaging.getToken({vapidKey:'BC2VWP3TnUUtVJm8IAhVG7wCqpykJXix96Eu-SbS-POmdpGkMvuGBypuzzSUq6ZHtq-qEEl_0-d7lt4KBFkZJgQ'}).then((currentToken) => {
      if (currentToken) {
        
        var ParametriRichiesta = new Object();
          ParametriRichiesta.token = Token;
          ParametriRichiesta.idgame = IDGame;
          ParametriRichiesta.tokenpush = currentToken;
  
          axios.post('https://play.airsoftdigital.com/api/registerpushtoken.php', JSON.stringify(ParametriRichiesta)).then(response => {
              if (response.data.status === "OK") {

              } else {
              }
          });

        setTokenFound(currentToken);
      } else {
        setTokenFound("");
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }


  export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});