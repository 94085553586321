import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import './ScorePanel.css';
import './StiliComuni.css';



class ScorePanel extends React.Component {
    constructor(props) {
        super(props);
    }

    render()
    {
        return <div className="containerScore">
                <div>
                <div className="ContainerPunteggioSingolo PunteggioRossoTotale">{parseInt(this.props.RedScore)}</div>
                <div className="ContainerPunteggioSingolo PunteggioBluTotale">{parseInt(this.props.BlueScore)}</div>
                <div className="ContainerPunteggioSingolo PunteggioGialloTotale">{parseInt(this.props.YellowScore)}</div>
                </div>
                </div>
    }
}

export default ScorePanel;
