import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import './AppMenu.css';

import PublicIcon from '@material-ui/icons/Public';
import PeopleIcon from '@material-ui/icons/People';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import PinDropIcon from '@material-ui/icons/PinDrop';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';



class AppMenu extends React.Component 
{
    constructor(props) {
        super(props);
        this.clickSuMappa = this.clickSuMappa.bind(this);

        this.state = {
        };
      }

    clickSuMappa= () =>
    {
      this.props.callback("map");
    }

    clickSuGestioneGame= () =>
    {
      this.props.callback("gam");
    }

    clickSuGestionePOI= () =>
    {
      this.props.callback("poi");
    }

    clickSuTeam= () =>
    {
      this.props.callback("team");
    }


    clickSuEsci= () =>
    {
      this.props.callback("exit");
    }

    render(){

        if(this.props.tipoUtente==="a")
        {
        return <div className="NavBarApp">
          <a className="bottoneNavBar" onClick={this.clickSuMappa}><PublicIcon style={{ fontSize: 40 }}/></a>
          <a className="bottoneNavBar" onClick={this.clickSuGestioneGame}><SettingsInputAntennaIcon style={{ fontSize: 40 }}/></a>
          <a className="bottoneNavBar" onClick={this.clickSuGestionePOI}><PinDropIcon style={{ fontSize: 40 }}/></a>
          <a className="bottoneNavBar" onClick={this.clickSuTeam}><PeopleIcon style={{ fontSize: 40 }}/></a>
          <a className="bottoneNavBar" onClick={this.clickSuEsci}><ExitToAppIcon style={{ fontSize: 40 }}/></a>
          </div>
        }
        else if(this.props.tipoUtente==="u")
        {
          return <div className="NavBarApp">
            <a className="bottoneNavBar" onClick={this.clickSuMappa}><PublicIcon style={{ fontSize: 40 }}/></a>
            <a className="bottoneNavBar" onClick={this.clickSuTeam}><PeopleIcon style={{ fontSize: 40 }}/></a>
            <a className="bottoneNavBar" onClick={this.clickSuEsci}><ExitToAppIcon style={{ fontSize: 40 }}/></a>
            </div>
          }
    };
}


export default AppMenu;