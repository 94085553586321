import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import ButtonCard from "./ButtonCard";
import './StiliComuni.css'
import InputTextPT from './InputTextPT' 
import SelectPT from './SelectPT'

class AggiungiPOI extends React.Component 
{
    constructor(props) {
        super(props);  

        this.state=
        {
            Token:this.props.Token,
            IDGame:this.props.IDGame,
        }
        this.ConfermaAggiunta = this.ConfermaAggiunta.bind(this);
        this.AnnullaAggiunta = this.AnnullaAggiunta.bind(this);
      }

    AnnullaAggiunta()
    {
        this.props.callbackNuovoPOI("KO");
    }

    ConfermaAggiunta()
    {
        var Nome = document.getElementById("nomePOI").value;
        var zPOI = document.getElementById("zPOI").value;
        var ePOI = document.getElementById("ePOI").value;
        var nPOI = document.getElementById("nPOI").value;
   
        if(Nome!="" && zPOI!="" && ePOI!="" && nPOI!="")
        {
            var ParametriRichiesta = new Object();
            ParametriRichiesta.token = this.state.Token;
            ParametriRichiesta.idgame = this.state.IDGame;
            ParametriRichiesta.cmd = "n";
            ParametriRichiesta.poiname = Nome;
            ParametriRichiesta.rect = zPOI;
            ParametriRichiesta.east = ePOI;
            ParametriRichiesta.north = nPOI;

            
            axios.post('https://play.airsoftdigital.com/api/poimngt.php', JSON.stringify(ParametriRichiesta)).then(response => {
            if (response.data.status === "OK") {
                this.props.callbackNuovoPOI("OK");
            }
            else
            {
                alert("Problemi di connessione.");
            }});
        }
        else
        {
            alert("È necessario completare tutti i campi.");
        }
    }



    render()
    {
       return <div className="CardGenerica"> 
       <div className="labelCard">Nome POI</div>
       <InputTextPT id="nomePOI" testo={this.props.nome}/>
       <div style={{height:30}}></div>
       <div className="titolettoCard">Coordinate UTM(WGS84)</div> 
       <div className="labelCard">Zona</div>
       <InputTextPT id="zPOI" testo={this.props.utm_z}/>
       <div className="labelCard">East</div>
       <InputTextPT id="ePOI" testo={this.props.utm_e}/>
       <div className="labelCard">North</div>
       <InputTextPT id="nPOI" testo={this.props.utm_n}/>
       
       
       <div><ButtonCard className="bottoneModaleAggiungi" testo="Conferma" colore="verde" click={this.ConfermaAggiunta}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<ButtonCard className="bottoneModaleAggiungi" testo="Annulla" click={this.AnnullaAggiunta}/></div>
       </div>
    };
}


export default AggiungiPOI;