import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import './ListaOperatori.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';




class ListaOperatori extends React.Component 
{
    constructor(props) {
        super(props);       

        this.state=
        {
            nome:this.props.nome,
            latitudine:this.props.latitudine,
            longitudine:this.props.longitudine,
            Token:this.props.Token,
            IDGame:this.props.IDGame,
            utm_zone:this.props.utm_zone,
            utm_east:this.props.utm_east,
            utm_north:this.props.utm_north,
            id:this.props.id,
            
        }
      }


    render()
    {
   
     
        return <div>
            <AccountCircleIcon/><div>OK</div>
        </div>
  
    };
}


export default ListaOperatori;